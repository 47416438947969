<template>
  <div class="ski-search">
    <HgSkiSearch
      allowHotjarRecord
      :is-mobile="isMobile"
      :search-url="searchUrl"
      :destinations="traveltrexDestinations"
      :dates="{
        startDate: new Date(),
        relativeEndDays: 540
      }"
      @onUpdateDestinationTerm="updateDestinationTerm"
      @onUpdateSearchParameters="updateSearchParameters"
      @onTriggerSearch="triggerSearch"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import traveltrexDestinationsQuery from '@/apollo/queries/ibe/traveltrexDestinations.gql';
import traveltrexSearchUrlQuery from '@/apollo/queries/ibe/traveltrexSearchUrl.gql';
import GtmEventsMixin from '@/mixins/GtmEventsMixin';
import { addDays, format } from 'date-fns';

export default {
  mixins: [GtmEventsMixin],
  data: () => ({
    searchUrl: '',
    destinationTerm: '',
    orderTypes: ['Destination', 'City', 'Region', 'POI', 'Accommodation'],
    orderedDestinations: [],
    traveltrexDestinations: [],
    selectedDestination: null,
    selectedDaterange: null,
    selectedTravelers: null,
    selectedDuration: ''
  }),
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile
    })
  },
  methods: {
    updateSearchParameters(params) {
      if (params.destination) {
        this.selectedDestination = params.destination;
      } else {
        this.selectedDestination = null;
      }

      if (params.dateRange) {
        this.selectedDaterange = params.dateRange;
      }

      if (params.travelers) {
        this.selectedTravelers = params.travelers;
      }
      this.selectedDuration = params.duration || 'any';

      this.getUrl();
    },
    async getUrl() {
      if (!this.selectedDaterange) return;
      if (!this.selectedTravelers) return;

      const urlQueryInput = {
        numberOfAdults: this.selectedTravelers.adults,
        agesOfChildren: this.selectedTravelers.ages,
        startDate: this.$dateFns.formatISO(this.selectedDaterange.start, { representation: 'date' }),
        endDate: this.$dateFns.formatISO(this.selectedDaterange.end, { representation: 'date' })
      };
      if (this.selectedDestination) {
        urlQueryInput.destinationId = parseInt(this.selectedDestination.id);
      }

      if (this.selectedDuration !== '') {
        if (this.selectedDuration === 'exact') {
          urlQueryInput.duration = '0';
        } else if (this.selectedDuration !== 'any') {
          urlQueryInput.duration = this.selectedDuration;
        } else if (this.selectedDuration === 'any') {
          urlQueryInput.duration = '1-365';
        }
      }

      try {
        const { data } = await this.$apollo.query({
          query: traveltrexSearchUrlQuery,
          variables: {
            input: urlQueryInput
          }
        });

        this.searchUrl = data.traveltrexSearchUrl;
      } catch (e) {
        console.warn('Error: ', e);
      }
    },

    updateDestinationTerm(term) {
      this.destinationTerm = term;
    },

    triggerSearch() {
      const durations = this.selectedDuration.split(',');
      this.$gtm.push({
        event: 'SKI_search_used',
        destination: this.destinationTerm,
        period: `${format(
          addDays(this.selectedDaterange.start, 1),
          'EE, dd.MM.yyyy'
        )} - ${format(
          addDays(this.selectedDaterange.end, 1),
          'EE, dd.MM.yyyy'
        )}`,
        travelDuration:
          durations[0] == durations[1] ? durations[0] : this.selectedDuration,
        travellers: this.selectedTravelers
      });
    }
  },
  apollo: {
    traveltrexDestinations: {
      query: traveltrexDestinationsQuery,
      variables() {
        return {
          country: this.destinationTerm
        };
      }
    }
  }
};
</script>
